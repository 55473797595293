<template>
  <div>
    <div class="content">
      <div class="filter-list-tpl">
        <div class="add-box">
          <Row>
            <Col span="4" offset="20">
              <div class="member-oprate">
                <router-link class="new-doc fr" :to="{name: 'ServicesProblemAdd'}">添加问题</router-link>
              </div>
            </Col>
          </Row>
        </div>
        <Table stripe :columns="columns" :data="problemList" @on-selection-change="selectOne"></Table>
        <Row>
          <Col span="12">
          <div class="table-oprate">
            <Button @click="delRecord" v-show="power.indexOf('会员管理--删除会员信息权限') > -1">删除</Button>
          </div>
          </Col>
          <Col span="12">
          <span class="records">共{{pageTotal}}条记录</span>
          </Col>
        </Row>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page :total="pageTotal" :current="param.page" :page-size="param.size" @on-change="changePage"></Page>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import problemService from '@/services/problemService';

export default {
	data() {
		return {
			param: {
				page: 1,
				size: 10,
				member_id: this.$route.params.member_id,
			},
			power: [],
			del: {
				problem_ids: '',
			},
			del_one: {
				problem_ids: '',
			},
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '上报日期', key: 'create_time', align: 'center' },
				{ title: '上报来源', key: 'source', align: 'center' },
				{ title: '会员姓名', key: 'member_name', align: 'center' },
				{ title: '所属分院', key: 'branch_name', align: 'center' },
				{ title: '联系电话', key: 'mobile', align: 'center' },
				{ title: '问题类型', key: 'problem_type', align: 'center' },
				{ title: '促进师', key: 'belong_booster_name', align: 'center' },
				{ title: '负责人', key: 'charge_name', align: 'center' },
				{ title: '状态', key: 'status_string', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						if (this.power.indexOf('会员管理--删除会员信息权限') > -1) {
							return h('div', [
								h(
									'a',
									{
										class: 'icon_eye',
										style: {
											margin: '0 10px',
										},
										on: {
											click: () => {
												this.goDetail(params.index);
											},
										},
									},
									'查看',
								),
								h(
									'a',
									{
										class: 'icon_del',
										style: {
											margin: '0 10px',
										},
										on: {
											click: () => {
												this.del_one_Plan(params.index);
											},
										},
									},
									'删除',
								),
							]);
						} else {
							return h('div', [
								h(
									'a',
									{
										class: 'icon_eye',
										style: {
											margin: '0 10px',
										},
										on: {
											click: () => {
												this.goDetail(params.index);
											},
										},
									},
									'查看',
								),
							]);
						}
					},
				},
			],
			problemList: [],
			pageTotal: 0,
		};
	},
	computed: {
		userInfo() {
			return this.$store.getters.userInfo;
		},
	},
	created() {
		this.getProblemList();
		this.power = this.userInfo.power;
	},
	methods: {
		selectOne(selection) {
			let arrBoostId = [];
			for (var i = 0; i < selection.length; i++) {
				arrBoostId.push(selection[i].id);
			}
			this.del.problem_ids = arrBoostId.join(',');
		},
		getProblemList() {
			problemService.getProblemList(this.param).then((data) => {
				this.problemList = data.list;
				this.pageTotal = data.row_size;
			});
		},
		changePage(page) {
			this.param.page = page;
			this.getProblemList();
		},
		goDetail(index) {
			this.$router.push({
				name: 'ServicesProblemDetail',
				params: {
					member_id: this.problemList[index].member_id,
					problem_id: this.problemList[index].id,
				},
			});
		},
		delRecord() {
			if (!this.del.problem_ids) {
				this.$Message.warning('请选择要删除的问题记录');
			} else {
				this.$Modal.confirm({
					title: '确认',
					content: '确定删除所选问题记录吗',
					onOk: () => {
						this.okdel();
					},
					// onCancel: () => {
					//   this.del.problem_ids = ''
					// }
				});
			}
		},
		del_one_Plan(index) {
			var obj = this.problemList[index];
			this.del_one.problem_ids = obj.id;
			this.$Modal.confirm({
				title: '确认',
				content: '确定删除该记录吗',
				onOk: () => {
					problemService.deleteProblem(this.del_one).then(() => {
						this.getProblemList();
					});
				},
				// onCancel: () => {
				//   this.del.problem_ids = ''
				// }
			});
		},
		okdel() {
			problemService.deleteProblem(this.del).then(() => {
				this.getProblemList();
			});
		},
	},
};
</script>

<style lang="css" scoped>
.content {background: #fff}
</style>
